import { Component } from '@angular/core'
import { Router } from '@angular/router';

@Component({
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss']
})
export class Error404Component {
  constructor(private router: Router) { }

  homeButtonClicked() {
    this.router.navigate(['']);
  }
}
