import { createSelector } from '@ngrx/store';
import { selectAuthState } from './../core/core.state';
import { AuthState } from './authentication.models';


export const selectAuth = createSelector(
    selectAuthState,
    (state: AuthState) => state
);

export const selectIsAuthenticated = createSelector(
    selectAuthState,
    (state: AuthState) => state.isAuthenticated
);

export const selectAccessToken = createSelector(
    selectAuthState,
    (state: AuthState) => state.accessToken
);

export const selectAccount = createSelector(
    selectAuthState,
    (state: AuthState) => state.account
);

export const selectProfile = createSelector(
  selectAuthState,
  (state: AuthState) => state.profile
);

export const selectUserFeatures = createSelector(
  selectAuthState,
  (state: AuthState) => state.userFeatures
);

export const selectUserFeaturesLoaded = createSelector(
  selectAuthState,
  (state: AuthState) => state.userFeaturesLoaded
);
