import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { data } from 'jquery';
import { Conversation } from '../models/note.model';
import { Constants } from '../../core/common/constants.model';

@Component({
  selector: 'app-acknowledge-alerts',
  templateUrl: './acknowledge-alerts.component.html',
  styleUrls: ['./acknowledge-alerts.component.scss']
})
export class AcknowledgeAlertsComponent implements OnInit {
  filesExpanded = false;
  createdDateMap = new Map<number, Date>();
  updatedDateMap = new Map<number, Date>();
  characterDisplayLimit = Constants.NOTE_CHARACTER_DISPLAY_LIMIT;
  characterLimit = Constants.NOTE_CHARACTER_LIMIT;
  noteDisplayValues: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<AcknowledgeAlertsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // Can pass in data using this
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.data.alerts.forEach(note => {

      if (note.updatedDate) {
        this.updatedDateMap.set(note.conversationID, new Date(note.updatedDate.toString()));
      } else {
        this.createdDateMap.set(note.conversationID, new Date(note.createdDate.toString()));
      }

      let noteValues = this.initNoteText(note.text);
      this.noteDisplayValues.push(noteValues);
    });
  }

  initNoteText(noteText): any {
    if(noteText.length > this.characterDisplayLimit) {
      return (
      {
        displayExpandButton: true,
        expanded:  false,
        noteText:  noteText.substring(0, this.characterDisplayLimit) + ' ...',
        initialText: noteText
      });

    } else {
      return (
        {
          displayExpandButton: false,
          expanded:  false,
          noteText:  noteText,
          initialText: noteText
        });
    }
  }

  toggleNoteText(index: number) {
    if (this.noteDisplayValues[index].expanded) {
      // Hide
      this.noteDisplayValues[index].expanded = false;
      this.noteDisplayValues[index].noteText = this.noteDisplayValues[index].initialText.substring(0, this.characterDisplayLimit) + ' ...';
    } else {
      // Show
      this.noteDisplayValues[index].expanded = true;
      this.noteDisplayValues[index].noteText = this.noteDisplayValues[index].initialText;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
