import { Action, createReducer, on } from '@ngrx/store';
import { MainMenuState } from './main-menu.state';
import {
  actionGetGlobalNavigation,
  actionGetGlobalNavigationFailure,
  actionGetGlobalNavigationSuccess } from './main-menu.actions';

export const initialState: MainMenuState = {
  menuItems: [],
  menuLoadError: null,
  menuLoaded: false
};

const reducer = createReducer(
  initialState,
  on(actionGetGlobalNavigation, (state) => ({
    ...state,
    menuLoadError: null
  })),
  on(actionGetGlobalNavigationSuccess, (state, { menu }) => ({
    ...state,
    menuItems: menu.navigation,
    menuLoaded: true,
    menuLoadError: null
  })),
  on(actionGetGlobalNavigationFailure, (state, { error}) => ({
    ...state,
    menuLoaded: false,
    menuLoadError: error
  }))
);

export function menuReducer(
  state: MainMenuState | undefined,
  action: Action
): MainMenuState {
  return reducer(state, action);
}
