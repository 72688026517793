import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {OpportunityService} from './opportunity.service';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class OpportunityRouteActivator implements CanActivate {
  constructor(private contactService: OpportunityService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if ( isFinite(route.params['id'])) { // Check if id is a number
      return this.contactService.getOpportunity(route.params['id']).pipe(
                                map(() => true ),
                                catchError((err => {
                                    if (err.status === '404') {
                                      this.router.navigate(['/404']);
                                    } else {
                                      console.log(err);
                                    }
                                    return of(false);
                                  })));
    }

    this.router.navigate(['/404']);
    return false;
  }

}
