import {
  ActionReducerMap,
  createFeatureSelector,
  MetaReducer
} from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { AuthState } from './../authentication/authentication.models';
import { authReducer } from './../authentication/authentication.reducer';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { initAuthStateFromSession } from './meta-reducers/init-auth-state-from-session.reducer';
import { RouterStateUrl } from './router/router.state';

import { NoteState } from '../note/models/note.model';
import { noteReducer } from '../note/note.reducer';
import { MainMenuState } from '../layouts/full/main-menu/main-menu.state';
import { menuReducer } from '../layouts/full/main-menu/main-menu.reducer';

export interface AppState {
  auth: AuthState;
  router: RouterReducerState<RouterStateUrl>;
  notes: NoteState;
  menu: MainMenuState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  router: routerReducer,
  notes: noteReducer,
  menu: menuReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage,
  initAuthStateFromSession
];

export const selectAuthState = createFeatureSelector<AppState, AuthState>(
  'auth'
);

export const selectMainMenuState = createFeatureSelector<AppState, MainMenuState>('menu');

export const selectRouterState = createFeatureSelector<
  AppState,
  RouterReducerState<RouterStateUrl>
>('router');


export const selectNoteState = createFeatureSelector<
AppState,
NoteState>
('notes');


