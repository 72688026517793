import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../../core/common/constants.model';
import { RestApiService } from '../../core/rest-api/rest-api.service';
import { GlobalNavigation } from '../../shared/menu-items/menu-items';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  private baseUrlSystem = Constants.STAR_API_BASEURL_SYSTEM;
  constructor(private restApiService: RestApiService) { }

  getGlobalNavigation(): Observable<GlobalNavigation> {
    return this.restApiService.get(this.baseUrlSystem + 'navigation');
  }
}
