import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Separator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface GlobalNavigation {
  navigation: Menu[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'home',
    name: 'Home',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'messages',
    name: 'Messages',
    type: 'sub',
    icon: 'text_snippet',
    children: [
      { state: 'xpress', name: 'Send Xpress Message', type: 'link' }
    ]
  },
  {
    state: 'objectives',
    name: 'Objectives',
    type: 'link',
    icon: 'my_location'
  },
  {
    state: 'sales',
    name: 'Sales',
    type: 'sub',
    icon: 'monetization_on',
    children: [
      { state: 'view', name: 'View Sales', type: 'link' },
      { state: 'new', name: 'Add Sale', type: 'link' }
    ]
  },
  {
    state: 'opportunities',
    name: 'Opportunities',
    type: 'sub',
    icon: 'group',
    children: [
      { state: 'view', name: 'View Opportunities', type: 'link' }
    ]
  },
  {
    state: 'leads',
    name: 'Leads',
    type: 'sub',
    icon: 'add_ic_call',
    children: [
      { state: 'view', name: 'View Leads', type: 'link' }
    ]
  },
  {
    state: 'admin',
    name: 'Admin Center',
    type: 'sub',
    icon: 'settings_application',
    children: [
      { state: 'roles', name: 'Roles', type: 'link' }
    ]
  },
  {
    state: 'help',
    name: 'Help',
    type: 'sub',
    icon: 'help',
    children: [
      { state: 'https://knowledgebase.connectfirstcu.com/m/93380/c/328401', name: 'Policy and Procedure', type: 'extTabLink' },
      { state: 'https://knowledgebase.connectfirstcu.com/m/99423/c/339249', name: 'User Guide', type: 'extTabLink' },
      { state: 'mailto:STARmrm@connectfirstcu.com', name: 'uniFI Post Launch Instant Help', type: 'extTabLink'}
    ]

  }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
