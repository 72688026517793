import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, from } from 'rxjs';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

import { RestApiService } from '../../core/rest-api/rest-api.service';
import { AppState } from '../../core/core.state';
import { OpportunitySearchTerm,
         Opportunity,
         OpportunitySearchResult,
         OpportunitySearchPagination,
         OpportunitiesViewTab,
         OpportunityUpdateFollowUpDate,
         OpportunityUpdateAssign,
         OpportunityUpdateStatus } from '../models/opportunity.model';
import { Constants } from '../../core/common/constants.model';
import { ProductGroup } from '../../shared/models/product-group.model';
import { Medium } from '../../shared/models/medium.model';
import { Channel } from '../../shared/models/channel.model';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Conversation, PagedConversations } from '../../shared/models/note.model';


@Injectable({
  providedIn: 'root'
})
export class OpportunityService {

  private baseUrlOpportunity = Constants.STAR_API_BASEURL_OPPORTUNITY;
  private baseUrlSystem = Constants.STAR_API_BASEURL_SYSTEM;
  private baseURLProduct = Constants.STAR_API_BASEURL_PRODUCT;

  constructor(private restApiService: RestApiService, private state: Store<AppState>) { }

  createOpportunity(opportunityInsertForm: FormData): Observable<any> {
    return this.restApiService.postForm(this.baseUrlOpportunity, opportunityInsertForm);
  }

  updateOpportunityFollowUpDate(opportunityUpdate: OpportunityUpdateFollowUpDate, id: number): Observable<Opportunity> {
    const url = this.baseUrlOpportunity + id + '/followup';
    return this.restApiService.put(url, opportunityUpdate)
    .pipe(
      mergeMap( () => this.restApiService.get(this.baseUrlOpportunity + id, null))
    );
  }

  updateOpportunityAssign(opportunityUpdate: OpportunityUpdateAssign, id: number): Observable<Opportunity> {
    const url = this.baseUrlOpportunity + id + '/assign';
    return this.restApiService.put(url, opportunityUpdate)
    .pipe(
      mergeMap( () => this.restApiService.get(this.baseUrlOpportunity + id, null))
    );
  }

  updateOpportunityStatus(opportunityUpdate: OpportunityUpdateStatus, id: number): Observable<Opportunity> {
    const url = this.baseUrlOpportunity + id + '/status';
    return this.restApiService.put(url, opportunityUpdate)
    .pipe(
      mergeMap( () => this.restApiService.get(this.baseUrlOpportunity + id, null))
    );
  }

  searchOpportunities(searchTerm: OpportunitySearchTerm,
    pagination: OpportunitySearchPagination): Observable<OpportunitySearchResult[]> {
    let httpParams = new HttpParams();

    Object.keys(searchTerm).forEach(function (key) {
      if (searchTerm[key] !== null) {
        if (Array.isArray(searchTerm[key]) && isNumeric(searchTerm[key][0])) {
          const arrayParamsString = searchTerm[key].map(item => item).join(',');
          httpParams = httpParams.append(key, arrayParamsString);

        } else {
          httpParams = httpParams.append(key, searchTerm[key]);
        }
      }
    });
    httpParams = httpParams.append('rows', pagination.pageSize.toString());
    httpParams = httpParams.append('pageNumber', pagination.page.toString());
    return this.restApiService.get(this.baseUrlOpportunity, httpParams);
  }

  getViewOpportunitiesNavigation(): Observable<OpportunitiesViewTab[]> {
    const url = this.baseUrlSystem + 'navigation/opportunity';
    return this.restApiService.get(url);
  }

  getChannels(): Observable<Channel[]> {
    const url = this.baseUrlSystem + 'channels';
    return this.restApiService.get(url, null);
  }

  getMedia(): Observable<Medium[]> {
    const url = this.baseUrlSystem + 'media';
    return this.restApiService.get(url, null);
  }

  getOpportunity(opportunityId: number): Observable<Opportunity> {
    const url = this.baseUrlOpportunity + opportunityId;
    return this.restApiService.get(url, null);
  }

  getProductGroups(): Observable<ProductGroup[]> {
    const url = this.baseURLProduct + 'group';
    return this.restApiService.get(url, null);
  }

  getNoteHistory(opportunityId: number, pageNumber: number, returnAlertNotes: boolean, returnPinnedNotes: boolean): Observable<PagedConversations> {
    const url = this.baseUrlOpportunity + opportunityId + '/conversation';
    let httpParams = new HttpParams();
    httpParams = httpParams.append('PageNumber', pageNumber.toString());
    httpParams = httpParams.append('ReturnAlertNotes', returnAlertNotes.toString());
    httpParams = httpParams.append('ReturnPinnedNotes', returnPinnedNotes.toString());
    return this.restApiService.get(url, httpParams);
  }

  createConversation(createConversationForm: FormData, id: number): Observable<any> {
    const url = this.baseUrlOpportunity + '/' + id + '/conversation';
    return this.restApiService.postForm(url, createConversationForm);
  }

}

