import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { RestApiService } from '../../core/rest-api/rest-api.service';
import { EmployeeProfile, EmployeeCEI } from '../../shared/models/employee.model';
import { SearchTermEmployee } from '../../shared/models/search-term-employee.model';
import { Constants } from '../../core/common/constants.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private baseUrlSystem = Constants.STAR_API_BASEURL_SYSTEM;

  constructor(private restApiService: RestApiService) { }

  searchEmployees(searchTermEmployee: SearchTermEmployee, includeAll: boolean): Observable<EmployeeCEI[]> {
    let httpParams = new HttpParams();
    Object.keys(searchTermEmployee).forEach(function (key) {
      if (searchTermEmployee[key] !== null) {
        httpParams = httpParams.append(key, searchTermEmployee[key]);
      }
    });
    if (includeAll) {
      httpParams = httpParams.append('includeAll', 'true');
    }
    return this.restApiService.get(this.baseUrlSystem + 'Employees', httpParams);
  }

  myProfile(): Observable<EmployeeProfile> {
    return this.restApiService.get(this.baseUrlSystem + 'myprofile', null);
  }

  userFeatures(): Observable<number[]> {
    return this.restApiService.get(this.baseUrlSystem + 'user/feature', null);
  }
}
