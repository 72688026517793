import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { tap, withLatestFrom, switchMap, map, catchError } from 'rxjs/operators';
import { SessionStorageService, AUTH_KEY } from '../core/session-storage/session-storage.service';
import {
  authLoginSuccessful,
  authRetrieveProfile,
  authStoreProfile,
  authRefreshToken,
  authRetrieveProfileFailure,
  authStoreUserFeatures,
  authRetrieveUserFeatures,
  authRetrieveUserFeaturesFailure
} from './authentication.actions';

import { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { AppState } from '../core/core.state';
import { selectAuth } from './authentication.selectors';
import { EmployeeService} from '../opportunity/services/employee.service';
import { of } from 'rxjs';
import { stringify } from 'querystring';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private sessionStorageService: SessionStorageService,
        private employeeService: EmployeeService,
        private router: Router
    ) { }

    saveState = createEffect(
      () => this.actions$.pipe(
          ofType(authStoreProfile, authRefreshToken, authStoreUserFeatures),
          withLatestFrom(this.store.pipe(select(selectAuth))),
          tap(([actions, authState]) => {
              this.sessionStorageService.setItem(AUTH_KEY, JSON.stringify(authState));
          })
      ),
      { dispatch: false }

  );

  profile = createEffect(() =>
  this.actions$.pipe(
      ofType(authRetrieveProfile),
      switchMap(
        action =>
          this.employeeService.myProfile().pipe(
            map( profile => authStoreProfile({profile})),
            catchError(errorRetrievingProfile => of(authRetrieveProfileFailure({ errorRetrievingProfile})))
          )
      )
    )
  );

  userFeatures = createEffect(() =>
  this.actions$.pipe(
      ofType(authRetrieveUserFeatures),
      switchMap(
        action =>
          this.employeeService.userFeatures().pipe(
            map( userFeatures => authStoreUserFeatures({userFeatures})),
            catchError(errorRetrievingUserFeatures => of(authRetrieveUserFeaturesFailure({ errorRetrievingUserFeatures})))
          )
      )
    )
  );
}
