import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { SearchContactResults } from '../../../shared/models/contact.model';
import { SearchTerm } from '../../../shared/models/search-term.model';


// Retrieving Search Results
export const actionContactSearchRetrieve = createAction(
  '[Contact] Retrieve Contact Search Results',
  props<{ searchTerm: SearchTerm }>()
);
export const actionContactSearchRetrieveSuccess = createAction(
  '[Contact] Retrieve Contact Search Results Success',
  props<{ searchContactResults: SearchContactResults[]}>()
);
export const actionContactSearchRetrieveFailure = createAction(
  '[Contact] Retrieve Contact Search Results Failure',
  props<{ errorLoadingSearchContactResults: HttpErrorResponse}>()
);

















