import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { map, mergeMap, take } from 'rxjs/operators';
import { AppState } from '../core.state';
import { selectAccessToken } from '../../authentication/authentication.selectors';

import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestApiService implements OnInit {

  private apiUrl = environment.StarAPIURL;
  private httpOptions = {
    headers: new HttpHeaders(),
    params: new HttpParams()
  };
  private tokenLoaded = new Subject();

  updateHeader() {
    return this.state.select(selectAccessToken).pipe(
      map((token) => {
      this.httpOptions.headers = new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'id_token' : token
        }
      );
    }), take(1));
  }

  updateHeaderFormData() {
    return this.state.select(selectAccessToken).pipe(map((token) => {
      this.httpOptions.headers = new HttpHeaders(
        {
          'id_token' : token
        }
      );
    }), take(1));
  }

  constructor(private httpClient: HttpClient, private state: Store<AppState>) { }

  get(url: string, params?: HttpParams): any {
    return this.updateHeader().pipe( mergeMap(() => {
      this.httpOptions.params = null;
      if (params !== null) { this.httpOptions.params = params; }
      return this.httpClient.get(this.apiUrl + url, this.httpOptions);
    }));
  }

  post(url: string, payload: any): any {
    return this.updateHeader().pipe(
    mergeMap(() => {
      this.httpOptions.params = null;
      return this.httpClient.post(this.apiUrl + url, payload, this.httpOptions);
    }));
  }

  postForm(url: string, payload: any): any {
    return this.updateHeaderFormData().pipe(
    mergeMap(() => {
      this.httpOptions.params = null;
      return this.httpClient.post(this.apiUrl + url, payload, this.httpOptions);
    }));
  }

  put(url: string, payload: any): any {
    return this.updateHeader().pipe(
    mergeMap(() => {
      this.httpOptions.params = null;
      return this.httpClient.put(this.apiUrl + url, payload, this.httpOptions);
    }));
  }

  putForm(url: string, payload: any): any {
    return this.updateHeaderFormData().pipe(
    mergeMap(() => {
      this.httpOptions.params = null;
      return this.httpClient.put(this.apiUrl + url, payload, this.httpOptions);
    }));
  }

  delete(url: string) {
    return this.updateHeader().pipe(
    mergeMap(() => {
      this.httpOptions.params = null;
      return this.httpClient.delete(this.apiUrl + url, this.httpOptions);
    }));
  }



  ngOnInit() {}
}
