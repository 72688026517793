import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { Contact } from '../../models/contact.model';

import { PersonInsert, PersonUpdate } from '../../models/person.model';
import { OrganizationInsert, OrganizationUpdate } from '../../models/organization.model';
import { PhoneInsert, PhoneUpdate } from '../../models/phone.model';
import { Branch } from '../../../shared/models/branch.model';
import { Conversation, PagedConversations } from '../../../shared/models/note.model';
import { OpportunitySearchTerm,
         OpportunitySearchResult,
         OpportunitySearchPagination } from '../../../opportunity/models/opportunity.model';
import { SaleSearchTerm, SaleSearchPagination, SaleSearchResult } from '../../../sale/models/sale.model';

// Retrieving Contact
export const actionContactRetrieveContact = createAction(
  '[Contact] Retrieve Contact',
  props<{ contactId: string }>()
);
export const actionContactResetContact = createAction(
  '[Contact] Reset Contact'
);
export const actionContactRetrieveContactSuccess = createAction(
  '[Contact] Retrieve Contact Success',
  props<{ currentContact: Contact }>()
);
export const actionContactRetrieveContactFailure = createAction(
  '[Contact] Retrieve Contact Failure',
  props<{ errorRetrievingContact: HttpErrorResponse}>()
);

// Creating new prospective contact
export const actionContactInsertPerson = createAction(
  '[Contact] Create Contact (Person)',
  props<{ contactInsert: PersonInsert }>()
);
export const actionContactInsertOrganization = createAction(
  '[Contact] Create Contact (Organization)',
  props<{ contactInsert: OrganizationInsert }>()
);
export const actionContactInsertSuccess = createAction(
  '[Contact] Create Contact Success',
  props<{ currentContact: Contact }>()
);
export const actionContactInsertFailure = createAction(
  '[Contact] Create Contact Failure',
  props<{ errorInsertingContact: HttpErrorResponse}>()
);

// Updating contact
export const actionContactUpdatePerson = createAction(
  '[Contact] Update Person',
  props<{ contactUpdate: PersonUpdate, contactId: number }>()
);
export const actionContactUpdateOrganization = createAction(
  '[Contact] Update Organization',
  props<{ contactUpdate: OrganizationUpdate, contactId: number }>()
);
export const actionContactUpdateSuccess = createAction(
  '[Contact] Update Contact Success',
  props<{ currentContact: Contact}>()
);
export const actionContactUpdateFailure = createAction(
  '[Contact] Update Contact Failure',
  props<{ errorUpdatingContact: HttpErrorResponse}>()
);

// Inserting phone
export const actionContactInsertPhone = createAction(
  '[Contact] Insert Phone',
  props<{ phoneInsert: PhoneInsert, contactId: number }>()
);
export const actionContactInsertPhoneSuccess = createAction(
  '[Contact] Insert Phone Success',
  props<{ insertPhoneId: number}>()
);
export const actionContactInsertPhoneFailure = createAction(
  '[Contact] Insert Phone Failure',
  props<{ errorUpdatingPhone: HttpErrorResponse}>()
);

// Updating phone
export const actionContactUpdatePhone = createAction(
  '[Contact] Update Phone',
  props<{ phoneUpdate: PhoneUpdate, phoneId: number }>()
);
export const actionContactUpdatePhoneSuccess = createAction(
  '[Contact] Update Phone Success'
  );
export const actionContactUpdatePhoneFailure = createAction(
  '[Contact] Update Phone Failure',
  props<{ errorUpdatingPhone: HttpErrorResponse}>()
);

// Deleting phone
export const actionContactDeletePhone = createAction(
  '[Contact] Delete Phone',
  props<{ phoneId: number}>()
);
export const actionContactDeletePhoneSuccess = createAction(
  '[Contact] Delete Phone Success'
);
export const actionContactDeletePhoneFailure = createAction(
  '[Contact] Delete Phone Failure',
  props<{ errorUpdatingPhone: HttpErrorResponse}>()
);

// Retrieving initial branches
export const actionBranchListRetrieve = createAction(
  '[Contact] Initial Branches Retrieve'
);
export const actionBranchListRetrieveSuccess = createAction(
  '[Contact] Retrieve Branches Success',
  props<{ branchList: Branch[]}>()
);
export const actionBranchListRetrieveFailure = createAction(
  '[Contact] Retrieve Branches Failure',
  props<{ errorLoadingBranchList: HttpErrorResponse}>()
);

// Retrieving notes
export const actionLoadContactNotes = createAction(
  '[Contact] Retrieve Notes',
  props<{ contactId: number, pageNumber: number, returnAlertNotes: boolean, returnPinnedNotes: boolean}>()
);
export const actionLoadContactNotesSuccess = createAction(
  '[Contact] Retrieve Notes Success',
  props<{ pagedConversations: PagedConversations }>()
);
export const actionLoadContactNotesFailure = createAction(
  '[Contact] Retrieve Notes Failure',
  props<{ errorLoadingConversations: HttpErrorResponse}>()
);

// Retrieving opportunities
export const actionLoadContactOpportunities = createAction(
  '[Contact] Retrieve Opportunities',
  props<{ opportunitySearchTerm: OpportunitySearchTerm,
     opportunitySearchPagination: OpportunitySearchPagination}>()
);
export const actionLoadContactOpportunitiesSuccess = createAction(
  '[Contact] Retrieve Opportunities Success',
  props<{ opportunities: OpportunitySearchResult[]}>()
);
export const actionLoadContactOpportunitiesFailure = createAction(
  '[Contact] Retrieve Opportunities Failure',
  props<{ errorLoadingOpportunities: HttpErrorResponse}>()
);

// Retrieving sales
export const actionLoadContactSales = createAction(
  '[Contact] Retrieve Sales',
  props<{ saleSearchTerm: SaleSearchTerm,
     saleSearchPagination: SaleSearchPagination}>()
);
export const actionLoadContactSalesSuccess = createAction(
  '[Contact] Retrieve Sales Success',
  props<{ sales: SaleSearchResult[]}>()
);
export const actionLoadContactSalesFailure = createAction(
  '[Contact] Retrieve Sales Failure',
  props<{ errorLoadingSales: HttpErrorResponse}>()
);





