import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  template: `<h1 mat-dialog-title>Warning</h1>
<div mat-dialog-content>
  <p>Are you sure you want to cancel? All information entered will be lost.</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" tabindex="2" cdkFocusInitial>Yes</button>
  <button mat-button (click)="onNoClick()" tabindex="-1">No</button>
</div>`
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // Can pass in data using this
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
