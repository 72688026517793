import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, mergeMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocalStorageService } from '../../../core/local-storage/local-storage.service';
import { SystemService } from '../../services/system.service';
import {
  actionGetGlobalNavigation,
  actionGetGlobalNavigationSuccess,
  actionGetGlobalNavigationFailure } from './main-menu.actions';
import { selectMainMenuState, AppState } from '../../../core/core.state';

export const MAINMENU_KEY = 'menu';

@Injectable()
export class MainMenuEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private systemService: SystemService,
  ) { }
  getMainMenu = createEffect(() =>
    this.actions$.pipe(
      ofType(actionGetGlobalNavigation),
      mergeMap(action =>
        this.systemService.getGlobalNavigation().pipe(
          map(menu => actionGetGlobalNavigationSuccess({ menu })),
          catchError(error => of(actionGetGlobalNavigationFailure({ error })))
        )
      )
    )
  );

  storeMainMenu = createEffect(() =>
    this.actions$.pipe(
      ofType(actionGetGlobalNavigationSuccess),
      withLatestFrom(this.store.pipe(select(selectMainMenuState))),
      tap(([actions, mainMenuState]) =>
        this.localStorageService.setItem(MAINMENU_KEY, mainMenuState)
      )
  ),
  { dispatch: false });

}
