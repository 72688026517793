import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/catch';
import { RoleService } from './role.service';
import { Store} from '@ngrx/store';
import { State } from '../admin.state';

@Injectable()
export class RoleRouteActivator implements CanActivate {
  constructor(private contactService: RoleService, private router: Router, public store: Store<State>,) {
  }

  canActivate(route: ActivatedRouteSnapshot){
    return true; // TODO: Implement route guard for admin roles
  }

}
