import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule,
         MatButtonModule,
         MatIconModule,
         MatCardModule,
         MatCheckboxModule,
         MatDatepickerModule,
         MatExpansionModule,
         MatFormFieldModule,
         MatInputModule,
         MatListModule,
         MatMenuModule,
         MatPaginatorModule,
         MatProgressBarModule,
         MatProgressSpinnerModule,
         MatRadioModule,
         MatSelectModule,
         MatSidenavModule,
         MatSlideToggleModule,
         MatStepperModule,
         MatTableModule,
         MatTabsModule,
         MatToolbarModule,
         MatTooltipModule } from '@angular/material';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog.component';
import { UnsavedDeactivateGuard } from './guards/UnsavedDeactivate.guard';
import { AcknowledgeAlertsComponent } from './dialogs/acknowledge-alerts.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  entryComponents: [ConfirmDialogComponent, AcknowledgeAlertsComponent],
  imports: [
    MaterialFileInputModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatStepperModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    FlexLayoutModule,
    MatPaginatorModule,
    OverlayModule,
    MatButtonToggleModule,
    MatDialogModule,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DigitOnlyDirective,
    ConfirmDialogComponent,
    AcknowledgeAlertsComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DigitOnlyDirective,
    MaterialFileInputModule,
    MatButtonToggleModule,
    ConfirmDialogComponent,
    AcknowledgeAlertsComponent
  ],
  providers: [MenuItems, UnsavedDeactivateGuard]
})
export class SharedModule {}
