import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalNavigation } from '../../../shared/menu-items/menu-items';

export const actionGetGlobalNavigation = createAction(
  '[MainMenu] Get Global Navigation',
  props<{ }>()
);

export const actionGetGlobalNavigationSuccess = createAction(
  '[MainMenu] Get Global Navigation Success',
  props<{menu: GlobalNavigation}>()
);

export const actionGetGlobalNavigationFailure = createAction(
  '[MainMenu] Get Global Navigation Failure',
  props<{ error: HttpErrorResponse }>()
);
