import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { SpinnerComponent } from './shared/spinner.component';
import { FullComponent } from './layouts/full/full.component';
import { AppProfilePicComponent } from './layouts/full/profile-pic/profile-pic.component';
import { Error404Component } from './layouts/full/errors/404.component'
import { ContactRouteActivator } from './contact/services/contact-route-activator.service'
import { OpportunityRouteActivator } from './opportunity/services/opportunity-route-activator.service';
import { RoleRouteActivator } from './admin/services/role-route-activator.service';
import { SearchComponent } from './layouts/full/search/search.component';
import { MainMenuComponent } from './layouts/full/main-menu/main-menu.component';
import { ForbiddenComponent } from './layouts/full/errors/forbidden/forbidden.component';
import { LoginComponent } from './shared/login/login.component';


@NgModule({
  declarations: [
    AppComponent,
    AppBlankComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    AppBreadcrumbComponent,
    FullComponent,
    AppProfilePicComponent,
    SearchComponent,
    MainMenuComponent,
    Error404Component,
    ForbiddenComponent,
    LoginComponent
  ],
  imports: [
    // angular [
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    // core
    CoreModule,
    // app
    AppRoutingModule,
    StoreDevtoolsModule
  ],
  providers: [ContactRouteActivator , OpportunityRouteActivator, RoleRouteActivator],
  bootstrap: [AppComponent]
})
export class AppModule {}
