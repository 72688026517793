export class Constants {

  public static get STAR_API_BASEURL_ROLE(): string { return 'v1/Role/'; }
  public static get STAR_API_BASEURL_SYSTEM(): string { return 'v1/System/'; }
  public static get STAR_API_BASEURL_CONTACT(): string { return 'v1/Contacts/'; }
  public static get STAR_API_BASEURL_MESSAGE(): string { return 'v1/Message/'; }
  public static get STAR_API_BASEURL_OPPORTUNITY(): string { return 'v1/Opportunity/'; }
  public static get STAR_API_BASEURL_PRODUCT(): string { return 'v1/Product/'; }
  public static get STAR_API_BASEURL_NOTE(): string { return 'v1/Note/'; }
  public static get STAR_API_BASEURL_LEAD(): string { return 'v1/Lead/'; }
  public static get STAR_API_BASEURL_SALE(): string { return 'v1/Sale/'; }
  public static get STAR_API_BASEURL_OBJECTIVE(): string { return 'v1/Objective/'; }


  public static get REGEX_URL(): string { return '^(https?://)?([\\dA-Za-z.-]+)\\.([A-Za-z.]{2,6})[/\\w .-]*/?$'; }
  public static get REGEX_EMAIL(): string { return '(?!.*\\.\\.)(^[^@\\.][^@\\s]+@[^@\\s]+\\.[^@\\s\\.]+$)'; }
  public static get REGEX_DIGITS(): string { return '[0-9]*'; }

  public static get STAR_PHONE_USE_CODE_DEFAULT(): string { return 'CELL'; }
  public static get STAR_PHONE_NUMBER_LIMIT(): number { return 5; }
  public static get STAR_PHONE_USE_CODES(): {[key: string]: string} {
    return {
      'BUS': 'Business',
      'CELL': 'Mobile',
      'FAX': 'Fax',
      'PER': 'Home',
      'VAC': 'Vacation'
    };
  }

  public static get STAR_SALUTATIONS(): string[] {
    return [
      '',
      'Dr.',
      'Judge',
      'Miss',
      'Mr.',
      'Mrs.',
      'Ms.'
    ];
  }

  public static get STAR_VALIDATION_MESSAGES():  {[key: string]: string}  {
    return {
      required: 'Required',
      minDigits2: 'Must be at least 2 digits.',
      minDigits5: 'Must be at least 5 digits.',
      minDigits9: 'Must be at least 9 digits.',
      minDigits10: 'Must be at least 10 digits.',
      maxDigits10: 'Cannot exceed 10 digits.',
      maxLength25: 'Cannot exceed 25 characters.',
      maxLength50: 'Cannot exceed 50 characters.',
      email: 'Please enter a valid email address.',
      website: 'Please enter a valid web address.',
      phone: 'Please enter a valid phone number.',
      extension: 'Please enter a valid extension number.',
      sin: 'Please enter a valid SIN number.',
      minCurrentDate: 'Date cannot be before current date.'
    };
  }

  public static get SEARCH_MIN_CHARACTERS(): number { return 2; }
  public static get SEARCH_PAGESIZE_DEFAULT(): number { return 25; }
  public static get NOTE_CHARACTER_DISPLAY_LIMIT(): number { return 300; }
  public static get NOTE_CHARACTER_LIMIT(): number { return 2000; }

  public static get STAR_CONTEXT_IDS(): {[key: string]: number} {
    return {
      'Administration': 1,
      'Estate': 2,
      'ContactTrackerHistory': 3,
      'DNAHistory': 4,
      'Fraud': 5,
      'InboundCall': 6,
      'PersonToPerson': 7,
      'MRMHistory': 8,
      'Opportunity': 9,
      'WBHistory': 10
    };
  }

}




