import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { authLoginSuccessful,
         authRefreshToken,
         authRetrieveProfile,
         authRetrieveUserFeatures } from '../../authentication/authentication.actions';
         import { actionGetGlobalNavigation } from '../../layouts/full/main-menu/main-menu.actions';
import { filter } from 'rxjs/operators';

import { AppState } from './../../core/core.module';
import { selectAccessToken } from '../../authentication/authentication.selectors';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-login',
  template: ``,
  styles: []
})
export class LoginComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  private tokenSubscription: Subscription;
  private accessToken: Observable<string> = this.store.select(selectAccessToken);


  constructor(
    public menuItems: MenuItems,
    private store: Store<AppState>,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private routerService: Router) { }

  ngOnInit() {
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        this.routerService.navigate(['403']);
        return;
      }
    });
    this.subscription = this.broadcastService.subscribe('msal:loginSuccess',
      () => {
        const userData = this.authService.getAccount();
        this.store.dispatch(authLoginSuccessful({ account: userData }));
      });
      this.broadcastService.subscribe('msal:acquireTokenSuccess', payload => {
        this.store.dispatch(authRefreshToken({ token: payload.idToken.rawIdToken }));
    });
    // The following is to take the id_token from the url on first login as the raw token
    // is not exposed on a msal:loginSuccess event. Eventually the API will use the Auth header
    // so this code will be unnecessary.
    if (this.routerService.url.match(/^.*#id_token=/)) {
      const token = this.routerService.url.replace(/^.*#id_token=/, '');
      this.store.dispatch(authRefreshToken({token: token}));
    }
    // ----------
    const account = this.authService.getAccount();
    if (account) {
        this.store.dispatch(authLoginSuccessful({ account: account }));
    }
    this.tokenSubscription = this.accessToken.pipe(filter(val => val !== ''))
      .subscribe( val => {
      if (val) {
        this.store.dispatch(authRetrieveProfile());
        this.store.dispatch(actionGetGlobalNavigation({}));
        this.store.dispatch(authRetrieveUserFeatures());
      }
    });
  }

  ngOnDestroy(): void {
    // this.mobileQuery.removeListener(this._mobileQueryListener);
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
  }

}
