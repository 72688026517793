import { Injectable } from '@angular/core';
import { AuthState } from '../../authentication/authentication.models';

export const AUTH_KEY = 'auth';
export const KEYARRAY : string[] = [
  'lead.leadsearch',
  'opportunity.opportunitysearch',
  'sale.salesearch',
]


@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  static loadInitialState() {
    return Object.keys(sessionStorage).reduce((state: any, storageKey) => {

      const currentStateRef = state;
      if (storageKey === AUTH_KEY) {
        currentStateRef[AUTH_KEY] = JSON.parse(JSON.parse(sessionStorage.getItem(AUTH_KEY)));
        currentStateRef[AUTH_KEY] = currentStateRef[AUTH_KEY] || {};
      }
      if(KEYARRAY.includes(storageKey)){
        const stateKeys = storageKey
          .toLowerCase()
          .split('.')
          .map(key =>

            key
              .split('-')
              .map((token, index) =>
                index === 0
                  ? token
                  : token.charAt(0).toUpperCase() + token.slice(1)
              )
              .join('')
          );

        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
        if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(sessionStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  setItem(key: string, value: any) {
    sessionStorage.setItem(`${key}`, JSON.stringify(value));
  }

  getItem(key: string) {
    return JSON.parse(sessionStorage.getItem(`${key}`));
  }

  removeItem(key: string) {

    sessionStorage.removeItem(`${key}`);
  }
}
