import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeeProfile } from '../shared/models/employee.model';
import { Account } from 'msal';

export const authLogin = createAction('[Auth] Login', props<{token: string}>());
export const authLoginSuccessful = createAction('[Auth] Login Successful', props<{account: Account}>());
export const authLoginFailed = createAction('[Auth] Login Failed');
export const authLogout = createAction('[Auth] Logout');
export const authRefreshToken = createAction('[Auth] Refresh Token', props<{token: string}>());
export const authRetrieveProfile = createAction('[Auth] Get Profile');
export const authStoreProfile = createAction('[Auth] Store Profile', props<{profile: EmployeeProfile}>());
export const authRetrieveProfileFailure = createAction(
  '[Auth] Retrieve Profile Failure',
  props<{ errorRetrievingProfile: HttpErrorResponse}>()
);
export const authRetrieveUserFeatures = createAction('[Auth] Get User Features');
export const authStoreUserFeatures = createAction(
  '[Auth] Store User Features',
  props<{userFeatures: number[]}>()
);
export const authRetrieveUserFeaturesFailure = createAction(
  '[Auth] Retrieve User Features Failure',
  props<{ errorRetrievingUserFeatures: HttpErrorResponse}>()
);
