import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Channel } from '../shared/models/channel.model';
import { Medium } from '../shared/models/medium.model';
import { NoteUpdate, ConversationCreate, ConversationCreateSuccess, ConversationUpdate, NoteCreateSuccess, OutboundCallResult } from './models/note.model';
import { Note, NoteFile, NoteFileDownload, NoteFileSuccess, PagedConversations, UpdateNoteSuccess } from '../shared/models/note.model';
import { Context } from '../shared/models/context.model';

// Retrieving initial contexts
export const actionContextListRetrieve = createAction(
  '[Note] Initial Contexts Retrieve'
);
export const actionContextListRetrieveSuccess = createAction(
  '[Note] Retrieve Contexts Success',
  props<{ contextList: Context[]}>()
);
export const actionContextListRetrieveFailure = createAction(
  '[Note] Retrieve Contexts Failure',
  props<{ errorLoadingContextList: HttpErrorResponse}>()
);

// Retrieving initial channels
export const actionChannelListRetrieve = createAction(
  '[Note] Initial Channels Retrieve'
);
export const actionChannelListRetrieveSuccess = createAction(
  '[Note] Retrieve Channels Success',
  props<{ channelList: Channel[]}>()
);
export const actionChannelListRetrieveFailure = createAction(
  '[Note] Retrieve Channels Failure',
  props<{ errorLoadingChannelList: HttpErrorResponse}>()
);

// Retrieving initial media
export const actionMediumListRetrieve = createAction(
  '[Note] Initial Media Retrieve'
);
export const actionMediumListRetrieveSuccess = createAction(
  '[Note] Retrieve Media Success',
  props<{ mediumList: Medium[]}>()
);
export const actionMediumListRetrieveFailure = createAction(
  '[Note] Retrieve Media Failure',
  props<{ errorLoadingMediumList: HttpErrorResponse}>()
);

// Updating note
export const actionNoteUpdate = createAction(
  '[Note] Update Note',
  props<{ noteUpdateForm: FormData, noteID: number }>()
);
export const actionNoteUpdateSuccess = createAction(
  '[Note] Update Note Success',
  props<{ noteUpdateResult: UpdateNoteSuccess}>()
);
export const actionNoteUpdateFailure = createAction(
  '[Note] Update Note Failure',
  props<{ errorUpdatingNote: HttpErrorResponse}>()
);

// Updating conversation
export const actionConversationUpdate = createAction(
  '[Note] Update Conversation',
  props<{ conversationUpdate: ConversationUpdate, conversationID: number, currentPath: string }>()
);
export const actionConversationUpdateSuccess = createAction(
  '[Note] Update Conversation Success',
  props<{ updatedDate: Date}>()
);
export const actionConversationUpdateFailure = createAction(
  '[Note] Update Conversation Failure',
  props<{ errorUpdatingConversation: HttpErrorResponse}>()
);

// Creating conversation
export const actionConversationCreate = createAction(
  '[Note] Create Conversation',
  props<{ conversationToCreate: FormData, currentPath: string}>()
);
export const actionConversationCreateSuccess = createAction(
  '[Note] Create Conversation Success',
  props<{ newConversationDetails: ConversationCreateSuccess}>()
);
export const actionConversationCreateFailure = createAction(
  '[Note] Create Conversation Failure',
  props<{ errorCreatingConversation: HttpErrorResponse}>()
);

// Creating note
export const actionNoteCreate = createAction(
  '[Note] Create Note',
  props<{ noteToCreate: FormData, currentPath: string, conversationID: number}>()
);
export const actionNoteCreateSuccess = createAction(
  '[Note] Create Note Success',
  props<{ newNoteDetails: NoteCreateSuccess}>()
);
export const actionNoteCreateFailure = createAction(
  '[Note] Create Note Failure',
  props<{ errorCreatingNote: HttpErrorResponse}>()
);

// Update preferences
export const actionUpdatePreferredChannel = createAction(
  '[Note] Update Preferred Channel',
  props<{ newChannel: string}>()
);

// Retrieving NoteFile
export const actionNoteFileRetrieve = createAction(
  '[Note] NoteFile Retrieve',
  props<{ noteFileID: number, noteID: number}>()
);
export const actionNoteFileRetrieveSuccess = createAction(
  '[Note] Retrieve NoteFile Success',
  props<{ noteFile: NoteFileDownload}>()
);
export const actionNoteFileRetrieveFailure = createAction(
  '[Note] Retrieve NoteFile Failure',
  props<{ errorLoadingNoteFile: HttpErrorResponse}>()
);

// Retrieving OutboundCallResults
export const actionOutboundCallResultsRetrieve = createAction(
  '[Note] OutboundCallResults Retrieve'
);
export const actionOutboundCallResultsRetrieveSuccess = createAction(
  '[Note] Retrieve OutboundCallResults Success',
  props<{ results: OutboundCallResult[]}>()
);
export const actionOutboundCallResultsRetrieveFailure = createAction(
  '[Note] Retrieve OutboundCallResults Failure',
  props<{ errorLoadingOutboundCallResults: HttpErrorResponse}>()
);

// Retrieving contact notes
export const actionLoadNotesForContact = createAction(
  '[Note] Retrieve Contact Notes',
  props<{ contactId: number, pageNumber: number, returnAlertNotes: boolean, returnPinnedNotes: boolean}>()
);
export const actionLoadNotesForContactSuccess = createAction(
  '[Note] Retrieve Contact Notes Success',
  props<{ pagedConversations: PagedConversations }>()
);
export const actionLoadNotesForContactFailure = createAction(
  '[Note] Retrieve Contact Notes Failure',
  props<{ errorLoadingConversations: HttpErrorResponse}>()
);

// Retrieving opportunity notes
export const actionLoadNotesForOpportunity = createAction(
  '[Note] Retrieve Opportunity Notes',
  props<{ opportunityId: number, pageNumber: number, returnAlertNotes: boolean, returnPinnedNotes: boolean}>()
);
export const actionLoadNotesForOpportunitySuccess = createAction(
  '[Note] Retrieve Opportunity Notes Success',
  props<{ pagedConversations: PagedConversations }>()
);
export const actionLoadNotesForOpportunityFailure = createAction(
  '[Note] Retrieve Opportunity Notes Failure',
  props<{ errorLoadingConversations: HttpErrorResponse}>()
);





