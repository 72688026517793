import { Component } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom,  } from 'rxjs/operators';
import { AppState, selectMainMenuState } from '../../../core/core.state';
import { MainMenuState } from './main-menu.state';
import { selectMenuItems } from './main-menu.selectors';
import { Menu } from '../../../shared/menu-items/menu-items';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  isActive = {};
  menuItems: Observable<Menu[]> = this.store.select(selectMenuItems);
  constructor(
    private store: Store<AppState>,
    router: Router) {
      router.events.pipe(
        filter(e => e instanceof RouterEvent),
        map(event => event as RouterEvent),
        withLatestFrom(this.menuItems)).subscribe(([e, menuItems]) => {
        const route = e.url.split('/');
          menuItems
          .forEach(x => {
            if (route && x.type === 'sub') {
              if ( x.state === route[1]) { this.isActive[x.state] = true; } else {this.isActive[x.state] = false; }
            }
        });
      });
  }

  openExternal(url: string) {
    window.open(url);
  }
}
