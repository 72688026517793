import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import { initialState } from '../../../authentication/authentication.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss']
})
export class AppProfilePicComponent implements OnInit, OnChanges {
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  @Input()
  size: number;
  @Input()
  name: string;


  private colours: string[] = ['#1abc9c',
  '#2ecc71', '#3498db', '#9b59b6',
  '#34495e', '#16a085', '#27ae60',
  '#2980b9', '#8e44ad', '#2c3e50',
  '#f1c40f', '#e67e22', '#e74c3c',
  '#95a5a6', '#f39c12', '#d35400',
  '#c0392b', '#bdc3c7', '#7f8c8d'];
  private ctx: CanvasRenderingContext2D;
  private initials: string;
  private colourIndex: number;

  constructor() { }

  setInitials(name: string ) {

    if (!name) {return; }

    const nameSplit = name.split(' ');
    let str = '';
    nameSplit.forEach(function (part) {
      str += part.charAt(0);
    });
    this.initials = str;
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.canvas.nativeElement.width = this.size + 3;
    this.canvas.nativeElement.height = this.size + 3;
    this.ctx.beginPath();
    this.colourIndex = (this.initials.charCodeAt(0) - 65) % 19;
    this.ctx.fillStyle = this.colours[this.colourIndex];
    this.ctx.arc(this.size / 2 + 1, this.size / 2 + 1, this.size / 2, 0, 2 * Math.PI, false);
    this.ctx.fill();
    this.ctx.font = (this.size / 2).toString() + 'px Arial';
    this.ctx.textAlign = 'center';
    this.ctx.fillStyle = '#FFF';
    this.ctx.fillText(str, this.size / 2 + 1 , this.size / 1.5 + 1);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#FFF';
    this.ctx.stroke();
  }


  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.name) {
      this.setInitials(changes.name.currentValue);
    }
  }

}
