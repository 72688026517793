import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { MsalGuard } from '@azure/msal-angular';
import { Error404Component } from './layouts/full/errors/404.component';
import { ForbiddenComponent } from './layouts/full/errors/forbidden/forbidden.component';

const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'contacts',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'opportunities',
        loadChildren: () => import('./opportunity/opportunity.module').then(m => m.OpportunityModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./objective/objective.module').then(m => m.ObjectiveModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'leads',
        loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'sales',
        loadChildren: () => import('./sale/sale.module').then(m => m.SaleModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'messages',
        loadChildren: () => import('./message/message.module').then(m => m.MessageModule),
        canActivate: [MsalGuard]
      },
      {
        path: '403',
        component: ForbiddenComponent,
        pathMatch: 'full'
      },
      {
        path: '404',
        component: Error404Component,
        pathMatch: 'full'
      },
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children : [
      {
        path: 'auth',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    component: Error404Component
  },
];
@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
