
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, RouterEvent } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  OnInit
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AppProfilePicComponent } from './profile-pic/profile-pic.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SearchComponent } from './search/search.component';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { authLoginSuccessful, authRefreshToken, authRetrieveProfile, authRetrieveUserFeatures } from '../../authentication/authentication.actions';
import { actionGetGlobalNavigation } from './main-menu/main-menu.actions';
import { filter, map } from 'rxjs/operators';

import {
  authLogin,
  selectIsAuthenticated,
  AppState
} from './../../core/core.module';

import {
  selectAccessToken,
  selectAccount
} from '../../authentication/authentication.selectors';
import { Account } from 'msal';
import { Observable, Subscription } from 'rxjs';
import { MainMenuComponent } from './main-menu/main-menu.component';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss']
})
export class FullComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green: boolean;
  blue: boolean;
  dark: boolean;
  minisidebar: boolean;
  boxed: boolean;
  danger: boolean;
  showHide: boolean;
  sidebarOpened;

  public showSearch = false;
  public user$: Observable<Account> = this.store.select(selectAccount);
  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;
  private subscription: Subscription;
  private tokenSubscription: Subscription;
  private accessToken: Observable<string> = this.store.select(selectAccessToken);

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private store: Store<AppState>,
    private broadcastService: BroadcastService,
    private authService: MsalService,

    private routerService: Router
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit() { }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }
}
