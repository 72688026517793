import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { mergeMap, tap } from 'rxjs/operators';
import { isNumeric } from 'rxjs/util/isNumeric';

import { Constants } from '../../core/common/constants.model';
import { RestApiService } from '../../core/rest-api/rest-api.service';
import { AppState } from '../../core/core.state';
import { PersonInsert } from '../models/person.model';
import { OrganizationInsert } from '../models/organization.model';
import { Contact, ContactUpdate } from '../models/contact.model';
import { SearchContactResults } from '../../shared/models/contact.model';
import { PhoneInsert, PhoneUpdate } from '../models/phone.model';
import { SearchTerm } from '../../shared/models/search-term.model';
import { Branch } from '../../shared/models/branch.model';
import { Conversation, PagedConversations } from '../../shared/models/note.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private baseUrlContact = Constants.STAR_API_BASEURL_CONTACT;
  private baseUrlSystem = Constants.STAR_API_BASEURL_SYSTEM;

  constructor(private restApiService: RestApiService, private state: Store<AppState>) { }

  updatePerson(contactUpdate: ContactUpdate, id: number): Observable<any> {
    const url = this.baseUrlContact + 'person/' + id;
    return this.restApiService.put(url, contactUpdate)
    .pipe(
      mergeMap( () => this.restApiService.get(url, null))
    );
  }

  updateOrganization(contactUpdate: ContactUpdate, id: number): Observable<any> {
    const url = this.baseUrlContact + 'organization/' + id;
    return this.restApiService.put(url, contactUpdate)
    .pipe(
      mergeMap( () => this.restApiService.get(url, null))
    );
  }

  insertPhone(phoneInsert: PhoneInsert, contactId: number): Observable<any> {
    const url = this.baseUrlContact +  contactId + '/phone';
    return this.restApiService.post(url, phoneInsert);
  }

  updatePhone(phoneUpdate: PhoneUpdate, phoneId: number): Observable<any> {
    const url = this.baseUrlContact + '/phone/' + phoneId;
    return this.restApiService.put(url, phoneUpdate);
  }

  deletePhone(phoneId: number): Observable<any> {
    const url = this.baseUrlContact + 'phone/' + phoneId;
    return this.restApiService.delete(url);
  }

  searchContacts(searchTerm: SearchTerm): Observable<SearchContactResults[]> {
    let httpParams = new HttpParams();
    Object.keys(searchTerm).forEach(function (key) {
      if (Array.isArray(searchTerm[key])) {
        for (const item of searchTerm[key]) {
          httpParams = httpParams.append(key, item);
        }
      } else {
        httpParams = httpParams.append(key, searchTerm[key]);
      }
    });
    return this.restApiService.get(this.baseUrlContact, httpParams);
  }

  createPerson(contact: PersonInsert): Observable<Contact> {
    return this.restApiService.post(this.baseUrlContact + 'person', contact)
    .pipe(
      mergeMap( id => this.restApiService.get(this.baseUrlContact + id, null))
    );
  }

  createOrganization(contact: OrganizationInsert): Observable<Contact> {
    return this.restApiService.post(this.baseUrlContact + 'organization', contact)
    .pipe(
      mergeMap( id => this.restApiService.get(this.baseUrlContact + id, null))
    );
  }

  getContact(contactId: string): Observable<Contact> {
    const url = this.baseUrlContact + contactId;
    return this.restApiService.get(url, null);
  }

  getBranchList(): Observable<Branch[]> {
    const url = this.baseUrlSystem + 'branches';
    return this.restApiService.get(url, null);
  }

  getNoteHistory(contactId: number, pageNumber: number, returnAlertNotes: boolean, returnPinnedNotes: boolean): Observable<PagedConversations> {
    const url = this.baseUrlContact + contactId + '/conversation';
    let httpParams = new HttpParams();
    httpParams = httpParams.append('PageNumber', pageNumber.toString());
    httpParams = httpParams.append('ReturnAlertNotes', returnAlertNotes.toString());
    httpParams = httpParams.append('ReturnPinnedNotes', returnPinnedNotes.toString());
    return this.restApiService.get(url, httpParams);
  }



}

