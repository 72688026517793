import { AuthState } from './authentication.models';
import { authLogin,
         authLogout,
         authRefreshToken,
         authLoginSuccessful,
         authStoreProfile,
         authStoreUserFeatures } from './authentication.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { SessionStorageService, AUTH_KEY } from '../core/session-storage/session-storage.service';

export const initialState: AuthState = {
    isAuthenticated : false,
    account: null,
    accessToken : '',
    profile: null,
    userFeatures: [],
    userFeaturesLoaded: false
};

const reducer = createReducer(
    initialState,
    on(authLogin, (state, {token}) => ({ ...state, isAuthenticated: true, accessToken: token })),
    on(authLoginSuccessful, (state, {account}) => ({...state, isAuthenticated: true, account: account})),
    on(authLogout, state => ({ ...state, isAuthenticated: false })),
    on(authRefreshToken, (state, {token}) => ({ ...state, isAuthenticated: true, accessToken: token })),
    on(authStoreProfile, (state, {profile}) => ({ ...state, profile: profile })),
    on(authStoreUserFeatures, (state, {userFeatures}) => ({
      ...state,
      userFeatures: userFeatures,
      userFeaturesLoaded: true
    }))
);

export function authReducer(
    state: AuthState | undefined,
    action: Action
): AuthState {
    return reducer(state, action);
}
