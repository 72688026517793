import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

import { ConfirmDialogComponent } from '../dialogs/confirm-dialog.component';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}


@Injectable({
  providedIn: 'root'
})
export class UnsavedDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor (public dialog: MatDialog) {}

  canDeactivate(component: CanComponentDeactivate) {
    // Allow navigation if the component says that it is OK or it doesn't have a canDeactivate function
    if (!component.canDeactivate || component.canDeactivate()) {
      return true;
    } else {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '250px',
        data: {  }
      });

      return dialogRef.afterClosed().pipe(
        map(result => result === true)
      );
    }
  }

}
