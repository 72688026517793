import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { actionChannelListRetrieve,
         actionChannelListRetrieveSuccess,
         actionChannelListRetrieveFailure,
         actionMediumListRetrieve,
         actionMediumListRetrieveSuccess,
         actionMediumListRetrieveFailure,
         actionNoteUpdate,
         actionNoteUpdateSuccess,
         actionNoteUpdateFailure,
         actionContextListRetrieve,
         actionContextListRetrieveSuccess,
         actionContextListRetrieveFailure,
         actionConversationCreate,
         actionConversationCreateSuccess,
         actionConversationCreateFailure,
         actionNoteCreate,
         actionNoteCreateSuccess,
         actionNoteCreateFailure,
         actionUpdatePreferredChannel,
         actionConversationUpdate,
         actionConversationUpdateSuccess,
         actionConversationUpdateFailure,
         actionNoteFileRetrieve,
         actionNoteFileRetrieveFailure,
         actionNoteFileRetrieveSuccess,
         actionOutboundCallResultsRetrieve,
         actionOutboundCallResultsRetrieveFailure,
         actionOutboundCallResultsRetrieveSuccess,
         actionLoadNotesForContact,
         actionLoadNotesForContactSuccess,
         actionLoadNotesForContactFailure,
         actionLoadNotesForOpportunity,
         actionLoadNotesForOpportunitySuccess,
         actionLoadNotesForOpportunityFailure
 } from './note.actions';
import { NoteState } from './models/note.model';
import { Conversation } from '../shared/models/note.model';

export const noteAdapter: EntityAdapter<Conversation> = createEntityAdapter<Conversation>({
});

export const initialState: NoteState = noteAdapter.getInitialState({
  ids: [],
  entities: { },

  contextList: null,
  loadingContextList: false,
  errorLoadingContextList: null,

  channelList: null,
  loadingChannelList: false,
  errorLoadingChannelList: null,

  mediumList: null,
  loadingMediumList: false,
  errorLoadingMediumList: null,

  isUpdating: false,
  noteUpdate: null,
  errorUpdatingNote: null,

  isUpdatingConversation: false,
  conversationUpdate: null,
  errorUpdatingConversation: null,

  isCreatingConversation: false,
  conversationCreate: null,
  errorCreatingConversation: null,

  isCreatingNote: false,
  noteCreate: null,
  errorCreatingNote: null,

  preferredChannel: null,

  noteFile: null,
  loadingNoteFile: false,
  errorLoadingNoteFile: null,

  outboundCallResults: null,
  loadingOutboundCallResults: false,
  errorLoadingOutboundCallResults: null,

  pagedConversations: null,
  loadingConversations: false,
  errorLoadingConversations: null,
});

const reducer = createReducer(
  initialState,

    // Retrieving context list
    on(actionContextListRetrieve, (state, { }) => ({
      ...state,
      contextList: null,
      loadingContextList: true
    })),
    on(actionContextListRetrieveSuccess, (state, { contextList }) => ({
      ...state,
      loadingContextList: false,
      contextList,
      errorLoadingContextList: null
    })),
    on(actionContextListRetrieveFailure, (state, { errorLoadingContextList }) => ({
      ...state,
      loadingContextList: false,
      errorLoadingContextList
    })),

  // Retrieving channel list
  on(actionChannelListRetrieve, (state, { }) => ({
    ...state,
    channelList: null,
    loadingChannelList: true
  })),
  on(actionChannelListRetrieveSuccess, (state, { channelList }) => ({
    ...state,
    loadingChannelList: false,
    channelList,
    errorLoadingChannelList: null
  })),
  on(actionChannelListRetrieveFailure, (state, { errorLoadingChannelList }) => ({
    ...state,
    loadingChannelList: false,
    errorLoadingChannelList
  })),

  // Retrieving medium list
  on(actionMediumListRetrieve, (state, { }) => ({
    ...state,
    mediumList: null,
    loadingMediumList: true
  })),
  on(actionMediumListRetrieveSuccess, (state, { mediumList }) => ({
    ...state,
    loadingMediumList: false,
    mediumList,
    errorLoadingMediumList: null
  })),
  on(actionMediumListRetrieveFailure, (state, { errorLoadingMediumList }) => ({
    ...state,
    loadingMediumList: false,
    errorLoadingMediumList
  })),

  // Updating note
  on(actionNoteUpdate, (state, { noteUpdateForm, noteID }) => ({
    ...state,
    isUpdating: true,
    errorUpdatingNote: null
  })),
  on(actionNoteUpdateSuccess, (state, { noteUpdateResult }) => ({
    ...state,
    noteUpdateResult,
    isUpdating: false,
    errorUpdatingNote: null
  })),
  on(actionNoteUpdateFailure, (state, { errorUpdatingNote }) => ({
    ...state,
    isUpdating: false,
    errorUpdatingNote
  })),

  // Updating conversation
  on(actionConversationUpdate, (state, { conversationUpdate, conversationID, currentPath }) => ({
    ...state,
    isUpdatingConversation: true,
    errorUpdatingConversation: null
  })),
  on(actionConversationUpdateSuccess, (state, {}) => ({
    ...state,
    isUpdatingConversation: false,
    errorUpdatingConversation: null
  })),
  on(actionConversationUpdateFailure, (state, { errorUpdatingConversation }) => ({
    ...state,
    isUpdatingConversation: false,
    errorUpdatingConversation
  })),

  // Creating conversation
  on(actionConversationCreate, (state, { conversationToCreate, currentPath }) => ({
    ...state,
    isCreatingConversation: true,
    errorCreatingConversation: null
  })),
  on(actionConversationCreateSuccess, (state, { newConversationDetails }) => ({
    ...state,
    newConversationDetails,
    isCreatingConversation: false,
    errorCreatingConversation: null
  })),
  on(actionConversationCreateFailure, (state, { errorCreatingConversation }) => ({
    ...state,
    isCreatingConversation: false,
    errorCreatingConversation
  })),

  // Creating note
  on(actionNoteCreate, (state, { noteToCreate, currentPath }) => ({
    ...state,
    isCreatingNote: true,
    errorCreatingNote: null
  })),
  on(actionNoteCreateSuccess, (state, { newNoteDetails }) => ({
    ...state,
    newNoteDetails,
    isCreatingConversation: false,
    errorCreatingConversation: null
  })),
  on(actionNoteCreateFailure, (state, { errorCreatingNote }) => ({
    ...state,
    isCreatingNote: false,
    errorCreatingNote
  })),

  // Updating user preferences
  on(actionUpdatePreferredChannel, (state, { newChannel }) => ({
    ...state,
    preferredChannel: newChannel
  })),

  // Retrieving NoteFile
  on(actionNoteFileRetrieve, (state, { }) => ({
    ...state,
    NoteFile: null,
    loadingNoteFile: true
  })),
  on(actionNoteFileRetrieveSuccess, (state, { noteFile }) => ({
    ...state,
    loadingNoteFile: false,
    noteFile,
    errorLoadingNoteFile: null
  })),
  on(actionNoteFileRetrieveFailure, (state, { errorLoadingNoteFile }) => ({
    ...state,
    loadingNoteFile: false,
    errorLoadingNoteFile
  })),

  // Retrieving OutboundCallResults
  on(actionOutboundCallResultsRetrieve, (state, { }) => ({
    ...state,
    outboundCallResults: null,
    loadingOutboundCallResults: true
  })),
  on(actionOutboundCallResultsRetrieveSuccess, (state, { results }) => ({
    ...state,
    loadingOutboundCallResults: false,
    results,
    errorLoadingOutboundCallResults: null
  })),
  on(actionOutboundCallResultsRetrieveFailure, (state, { errorLoadingOutboundCallResults }) => ({
    ...state,
    loadingOutboundCallResults: false,
    errorLoadingOutboundCallResults
  })),

  // Retrieving contact note history
  on(actionLoadNotesForContact, (state, { contactId, pageNumber, returnAlertNotes, returnPinnedNotes }) => ({
    ...state,
    loadingConversations: true,
    errorLoadingConversations: null,
    pagedConversations: null
  })),
  on(actionLoadNotesForContactSuccess, (state, { pagedConversations }) => ({
    ...state,
    pagedConversations,
    loadingConversations: false,
    errorLoadingConversations: null
  })),
  on(actionLoadNotesForContactFailure, (state, { errorLoadingConversations }) => ({
    ...state,
    loadingConversations: false,
    errorLoadingConversations
  })),

  // Retrieving opportunity note history
  on(actionLoadNotesForOpportunity, (state, { opportunityId, pageNumber, returnAlertNotes, returnPinnedNotes }) => ({
    ...state,
    loadingConversations: true,
    errorLoadingConversations: null,
    pagedConversations: null
  })),
  on(actionLoadNotesForOpportunitySuccess, (state, { pagedConversations }) => ({
    ...state,
    pagedConversations,
    loadingConversations: false,
    errorLoadingConversations: null
  })),
  on(actionLoadNotesForOpportunityFailure, (state, { errorLoadingConversations }) => ({
    ...state,
    loadingConversations: false,
    errorLoadingConversations
  })),
);

export function noteReducer(state: NoteState | undefined, action: Action) {
  return reducer(state, action);
}
