import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { SearchContactResults } from '../../../shared/models/contact.model';
import { Router } from '@angular/router';
import { ContactService } from '../../../contact/services/contact.service';
import { Constants } from '../../../core/common/constants.model';
import { SearchTerm, SearchTypes } from '../../../shared/models/search-term.model';
import { Store } from '@ngrx/store';
import { State } from '../../../contact/ngrx/contact.state';
import { actionContactSearchRetrieve } from '../../../contact/ngrx/contact-search/contact-search.action';
import { actionContactResetContact } from '../../../contact/ngrx/contact/contact-entity.actions';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  minSearchCharacters = Constants.SEARCH_MIN_CHARACTERS;
  defaultSearchPageSize = Constants.SEARCH_PAGESIZE_DEFAULT;

  searchTypes: SearchTypes[] = [
    { type: 'All', name: 'All' },
    { type: 'Name', name: 'Name' },
    { type: 'Phone', name: 'Phone' },
    { type: 'Email', name: 'Email' },
    { type: 'Member', name: 'Member #' },
  ];
  selectedType: string;
  contactCtrl = new FormControl();
  filteredContacts: Observable<SearchContactResults[]>;
  isLoading = false;
  isError = false;
  hasResults = true;
  searchTerm: SearchTerm;
  statusSearch = 'true';


  constructor(
    private router: Router,
    public store: Store<State>,
    private contactService: ContactService
  ) {
    this.contactCtrl.valueChanges
    .pipe(
      debounceTime(500),
      tap( value => {
        if (value !== null && value.length >= this.minSearchCharacters) {
          this.filteredContacts = null,
          this.isLoading = true;
          this.isError = false;
          this.hasResults = true;
          this.searchTerm = this.setSearchTerm(true);
        }
      }),
      switchMap(value => (
        value !== null && value.length >= this.minSearchCharacters ?
          this.contactService.searchContacts(this.searchTerm)
          .catch(err => {
              this.isError = true;
              return of({SearchContactResults: []});
            })
          :
          of({SearchContactResults: []}))
        )
    )
    .subscribe(data => {
      if (data['contacts'] === undefined) {
        this.filteredContacts = null;
        this.isLoading = false;
      } else {
        if (data['contacts'].length === 0) {
          this.hasResults = false;
        }
        this.filteredContacts = data['contacts'];
        this.isLoading = false;
        this.isError = false;
      }
    });
  }

  setSearchStatus(event) {
    this.statusSearch = event.value;
  }

  private setSearchTerm(isAutoComplete: boolean): SearchTerm {

    const search: SearchTerm = <SearchTerm>{};
    search.active = JSON.parse(this.statusSearch);

    if (!isAutoComplete) {
      search.pageNumber = 1;
      search.rows = this.defaultSearchPageSize;
      search.sort = 'Name';
      search.order = 'asc';
    }

    const searchValue = this.contactCtrl.value;
    switch (this.selectedType.toLowerCase()) {
      case 'name': {
        search.name = searchValue;
        break;
      }
      case 'phone' : {
        search.phone = searchValue;
        break;
      }
      case 'email' : {
        search.email = searchValue;
        break;
      }
      case 'member' : {
        search.memberNumber = searchValue;
        break;
      }
      default : {
        search.memberNumber = searchValue;
        search.name = searchValue;
        search.phone = searchValue;
        search.email = searchValue;
        break;
      }
    }
    return search;
  }

  routeAddNew() {
    this.router.navigate(['/contacts']);
    this.contactCtrl.reset();
  }

  routeSearchResults() {
    const searchTerm = this.setSearchTerm(false);
    this.store.dispatch(actionContactSearchRetrieve({ searchTerm }));

    this.router.navigate(['/contacts/search-results']);
    this.contactCtrl.reset();
  }

  routeSelectedContact(event) {
    this.contactCtrl.reset();
    if (this.router.url !== '/contacts/' + event.option.value && event.option.value !== undefined) {
      this.store.dispatch(actionContactResetContact());
      this.router.navigate(['/contacts/' + event.option.value]);
    }
  }

  ngOnInit() {
    this.selectedType = 'All';
  }

}
