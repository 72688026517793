import { Injectable } from '@angular/core';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { Store } from '@ngrx/store';

import { RestApiService } from '../../core/rest-api/rest-api.service';
import { AppState } from '../../core/core.state';
import { Role, RoleInsert, RoleUpdate } from '../models/roles.model';
import { Feature } from '../models/feature.model';
import { AdGroup } from '../models/adGroup.model';
import { Employee } from '../../shared/models/employee.model';
import { Constants } from '../../core/common/constants.model';


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private baseUrlRole = Constants.STAR_API_BASEURL_ROLE;
  private baseUrlSystem = Constants.STAR_API_BASEURL_SYSTEM;

  constructor(private restApiService: RestApiService, private state: Store<AppState>) { }

  createRole(role: RoleInsert): Observable<Role> {
    return this.restApiService.post(this.baseUrlRole, role)
    .pipe(
      mergeMap( id => this.restApiService.get(this.baseUrlRole + id, null))
    );
  }

  updateRole(role: RoleUpdate, id: number): Observable<Role> {
    const url = this.baseUrlRole + id;
    return this.restApiService.put(url, role)
    .pipe(
      mergeMap( () => this.restApiService.get(url, null))
    );
  }

  getAdGroups(): Observable<AdGroup[]> {
    const url = this.baseUrlSystem + 'adgroup';
    return this.restApiService.get(url, null);
  }

  getGroupMembers(currentAdGroupId: string): Observable<Employee[]> {
    const url = this.baseUrlSystem + 'adgroup/' + currentAdGroupId + '/members';
    return this.restApiService.get(url, null);
  }

  getRoles(): Observable<Role[]> {
    const url = this.baseUrlRole;
    return this.restApiService.get(url, null);
  }

  getFeatures(): Observable<Feature[]> {
    const url = this.baseUrlSystem + 'feature';
    return this.restApiService.get(url, null);
  }

}
